<script setup>
const props = defineProps({
  scrolledElement: Object
})

const visibleAll = ref(true);

const refreshScrolledElement = () => {
  if(props.scrolledElement && props.scrolledElement?.refresh) {
    props.scrolledElement.refresh();
  }
}
</script>

<template>
  <div class="flex justify-center items-center">
    <div class="w-6" :class="{'opacity-30':!visibleAll}" @click="() => props.scrolledElement.prevSlide()">
      <IconChevronLeft class="!w-3.5 !h-3.5 cursor-pointer" />
    </div>
    <div class="grow">
      <ScrollBarHorizontal :scrolledElement="props.scrolledElement" @visibilityChange="(v) => visibleAll = v" @scrollEnd="refreshScrolledElement"/>
    </div>
    <div class="w-6" :class="{'opacity-30':!visibleAll}" @click="() =>  props.scrolledElement.nextSlide()">
      <IconChevronRight class="ml-2 !w-3.5 !h-3.5 cursor-pointer"/>
    </div>
  </div>
</template>
